exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-spaces-tsx": () => import("./../../../src/pages/spaces.tsx" /* webpackChunkName: "component---src-pages-spaces-tsx" */),
  "component---src-pages-studio-process-tsx": () => import("./../../../src/pages/studio/process.tsx" /* webpackChunkName: "component---src-pages-studio-process-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-pdf-tsx": () => import("./../../../src/templates/pdf.tsx" /* webpackChunkName: "component---src-templates-pdf-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */)
}

