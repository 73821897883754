import "@wordpress/block-library/build-style/style.css";
import "@wordpress/block-library/build-style/theme.css";
import "@wordpress/block-library/build-style/common.css";
import "./src/styles/global.css";
import { AnimatePresence } from "framer-motion";
import React from "react";

export function wrapPageElement({ element, props }) {
  return <AnimatePresence mode="wait">{element}</AnimatePresence>;
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.scrollTo(...[0, 0]);

  return false;
};
